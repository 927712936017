<template>
  <v-app>
    <!-- <v-img class="hero" aspect-ratio="16/9" cover> </v-img> -->
    <!-- <div class="form"> -->
    <v-alert
      :value="status ? true : false"
      style="position: absolute; z-index: 1000; width: 47.5%; margin: 10px"
      dense
      outlined
      prominent
      shaped
      text
      :type="status"
      transition="scale-transition"
      dismissible
    >
      {{ statusMsg }}</v-alert
    >
    <div class="d-flex h-screen">
      <div class="bgColor h-screen">
        <div style="width: 75%; padding: 15px">
          <header>
            <h3 style="font-size: 30px">Welcome back</h3>
          </header>
          <div>
            <p style="font-size: 10px; color: gray">
              Please enter your details.
            </p>
          </div>
        </div>
        <v-form
          style="width: 75%; padding: 15px"
          v-model="fieldObj.form"
          @submit.prevent="onSubmit"
        >
          <v-text-field
            v-model="fieldObj.email"
            :readonly="fieldObj.loading"
            :rules="[required, rules.email]"
            class="mb-2"
            clearable
            label="Email"
            variant="solo"
            type="email"
          ></v-text-field>

          <v-text-field
            v-model="fieldObj.password"
            :readonly="fieldObj.loading"
            :rules="[required]"
            class="mb-2"
            clearable
            label="Password"
            variant="solo"
            type="password"
          ></v-text-field>

          <br />

          <v-btn
            :disabled="!fieldObj.form"
            :loading="isLoading"
            block
            color="success"
            size="large"
            type="submit"
            variant="elevated"
          >
            Sign In
          </v-btn>
        </v-form>
      </div>
      <div class="two">
        <v-img
          class="img"
          aspect-ratio="16/9"
          cover
          src="https://images.unsplash.com/photo-1614151135857-34e0468e04b1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
        ></v-img>
      </div>
    </div>

    <!-- </div> -->
  </v-app>
</template>

<script setup>
import { reactive, onMounted, ref, watch } from "vue";
import axios from "axios";
import { Motion } from "motion/vue";
import {
  BASE_URL,
  CLIENT_ID_UNSPLASH,
  END_POINT_UNSPLASH,
} from "../../constant/API";
import router from "@/router";
const fieldObj = reactive({
  form: false,
  email: null,
  password: null,
  loading: false,
});
const isLoading = ref(false);
const rules = reactive({
  required: (value) => !!value || "Required.",
  counter: (value) => value.length <= 20 || "Max 20 characters",
  email: (value) => {
    const pattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return pattern.test(value) || "Invalid e-mail.";
  },
});

const images = reactive([]);
const isError = ref(false);
const status = ref(null);
const statusMsg = ref(null);
const getRandomImgs = async () => {
  const data = await axios.get(
    `${END_POINT_UNSPLASH}/search/photos?page=1&query=store&client_id=${CLIENT_ID_UNSPLASH}`
  );

  const response = await data.data;
  images.push(response.results);
  // images = [...images, ...response.results];
  // console.warn("images : ", response.results);
};
onMounted(() => {
  getRandomImgs();
});
// watch(status, (newX) => {
//   if (newX) {
//     setTimeout(() => (status.value = null), 5000);
//   }
// });
function onSubmit() {
  try {
    console.error(fieldObj);
    if (!fieldObj.form) return;

    isLoading.value = true;
    axios
      .post(`${BASE_URL}/login`, {
        email: fieldObj.email,
        password: fieldObj.password,
      })
      .then((data) => {
        if (!(+data.status === 200 || +data.status === 201)) {
          status.value = "error";
          isLoading.value = false;
          statusMsg.value = data.data?.data.msg;
          // console.log(
          //   "data?.data",
          //   data?.data,
          //   data?.data?.data.msg,
          //   statusMsg.value
          // );
          setTimeout(() => (status.value = null), 5000);
          return;
        }
        if (!!!data?.data?.data.result) {
          status.value = "error";
          isLoading.value = false;
          statusMsg.value = data.data?.data.msg;
          // console.log(
          //   "data?.data",
          //   data?.data,
          //   data?.data?.data.msg,
          //   statusMsg.value
          // );
          setTimeout(() => (status.value = null), 5000);
          return;
        }
        status.value = "success";
        isLoading.value = false;
        // $router.push("Home");
        localStorage.setItem(
          "user_details",
          JSON.stringify({
            email: fieldObj.email,
            password: fieldObj.password,
            userId: data.data?.data?.userId,
          })
        );
        statusMsg.value = data.data?.data.msg;
        setTimeout(() => {
          status.value = null;
          router.push({ name: "Home" });
        }, 3000);

        // console.log("data?.data", router);

        // router.push({ path: "Home" });
      })
      .catch((error) => {
        isLoading.value = false;
      });
  } catch (error) {
    isLoading.value = false;
  }

  // setTimeout(() => (data.loading = false), 2000);
}
function required(v) {
  return !!v || "Field is required";
}

// export default {
//   data: () => ({
//     form: false,
//     email: null,
//     password: null,
//     loading: false,
//   }),

//   methods: {
//     onSubmit() {
//       if (!data.form) return;

//       data.loading = true;

//       setTimeout(() => (data.loading = false), 2000);
//     },
//     required(v) {
//       return !!v || "Field is required";
//     },
//   },
// };
</script>
<style scoped>
.hero {
  background: url("https://images.unsplash.com/photo-1441986300917-64674bd600d8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80");
  background-size: cover;
  height: 100vh;
  filter: blur(5px);
}
.img {
  width: 100%;
  height: 100vh;
  /* position: absolute;
  display: flex;
  justify-content: center;
  align-items: center; */
}

.bgColor {
  width: 50%;
  height: 100vh;
  /* background-color: #3c3d6c; */
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.two {
  width: 50%;
  height: 100vh;
  /* background-color: #3c3d6c; */
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.div {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  background-color: var(--splash);
}
</style>
