import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import moment from "moment";
import axios from "axios";
import "./global/commonStyle.css";
import VueVideoPlayer from "vue-video-player";
import { VueQueryPlugin } from "vue-query";
import VueCompositionAPI from "@vue/composition-api";
import "video.js/dist/video-js.css";
import InfiniteLoading from "vue-infinite-loading";

Vue.config.productionTip = false;

Vue.use(moment);

new Vue({
  InfiniteLoading,
  VueCompositionAPI,
  VueQueryPlugin,
  VueVideoPlayer,
  vuetify,
  router,
  moment,
  axios,
  render: (h) => h(App),
}).$mount("#app");
