<style>
@font-face {
  font-family: Axiforma-Medium;
  src: url("../assets/font/Axiforma-Medium.ttf");
}
@font-face {
  font-family: Axiforma-Bold;
  src: url("../assets/font/Axiforma-Bold.ttf");
}
body {
  font-family: Axiforma-Medium !important;
}
* {
  font-family: Axiforma-Medium !important;
}

.link-design {
  color: #333 !important;
  text-decoration: none;
}
.link-head-design {
  font-weight: 600;
}
#inspire {
  /* background: #f2f2f2 !important; */
}
.side-bar-cus {
  /* background: #f2f2f2 !important; */
}
.v-application a {
  color: unset !important;
}

.v-list-item__content {
  padding: 8px 0 !important;
}
.v-expansion-panel--active > .v-expansion-panel-header {
  min-height: unset !important;
}
.v-expansion-panel-content__wrap {
  padding: unset !important;
}
.v-expansion-panel-header,
.v-expansion-panel-header
  > .theme--light.v-expansion-panels
  .v-expansion-panel-header
  .v-expansion-panel-header__icon
  .v-icon {
  background: #fff !important;
  color: white !important;
}
.v-expansion-panel-header--active,
.v-expansion-panel-header--active > .mdi-chevron-up {
  background: #388ffe !important;
  color: #fff !important;
}
.pi {
  font-family: "primeicons" !important;
}
</style>
<template>
  <v-app id="inspire">
    <v-navigation-drawer
      app
      permanent
      :key="drawer"
      :expand-on-hover="!drawer"
      fixed
      clipped
      class="drawer-style"
      id="style-1"
      style="background: rgb(34, 110, 179) !important; color: #fff"
    >
      <v-divider></v-divider>
      <v-list dense dark style="background: rgb(34, 110, 179) !important">
        <span v-for="item in items" :key="item.id">
          <!-- If has childeren -->
          <v-list-group
            v-if="item.has_children"
            :prepend-icon="item.icon"
            no-action
            color="orange"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title
                  v-text="item.name"
                  style="color: black"
                  class=".font-weight-black"
                ></v-list-item-title>
              </v-list-item-content>
            </template>
            <span v-for="child in item.children" :key="child.key">
              <div v-if="child.child">
                <v-list-item :to="child.to" :title="child.name" class="pl-10">
                  <v-list-item-icon>
                    <v-icon v-text="child.icon" style="color: black"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      v-text="child.name"
                      style="color: black"
                      class="Medium weight text."
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </div>
              <v-list-group
                v-if="!child.child"
                no-action
                sub-group
                color="orange"
              >
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title v-text="child.name"></v-list-item-title>
                  </v-list-item-content>
                </template>

                <v-list-item
                  v-for="subchild in child.children"
                  :key="subchild.key"
                  :to="subchild.to"
                  :title="subchild.name"
                  class="pl-14"
                >
                  <v-list-item-icon>
                    <v-icon v-text="subchild.icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      v-text="subchild.name"
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
            </span>
          </v-list-group>

          <!-- If No has childeren -->
          <v-list-item
            :to="item.to"
            :title="item.name"
            v-if="!item.has_children"
            color="red"
          >
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.name"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </span>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar  elevate-on-scroll app clipped-left>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <div class="d-flex align-center">
        <div>
          <img
            src="../assets/vikalpScreen.png"
            width="125px"
            height="55px"
            class="pa-0"
          />
          <!-- <img
          src="../assets/logo.png"
          width="130px"
          height="40px"
          class="pa-0"
          v-if="drawer"
        /> -->
        </div>
      </div>

      <v-spacer></v-spacer>

      <!-- <v-btn icon>
        <v-icon>mdi-magnify</v-icon>
      </v-btn>

      <v-btn icon>
        <v-icon>mdi-heart</v-icon>
      </v-btn> -->
      <div>
        <template>
          <v-row justify="center">
            <v-dialog v-model="dialog" persistent max-width="350">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  style="background-color: transparent"
                  elevation="0"
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon style="color: red">mdi-logout</v-icon>

                  <p style="color: red; position: relative; top: 10px">
                    Log Out
                  </p>
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="text-h6"> Are you sure? </v-card-title>
                <v-card-text
                  >This action will log you out from your account.</v-card-text
                >
                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn color="error darken-1" text @click="dialog = false">
                    No,stay
                  </v-btn>

                  <v-btn color="error" @click="signOut()">
                    Logout
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </template>
      </div>
    </v-app-bar>

    <v-main :style="style_padding">
      <router-view :key="$route.path"></router-view>
    </v-main>
  </v-app>
</template>

<script>
import router from "@/router";
import axios from "axios";
export default {
  // computed() {
  //   {"paddingTop":"64px","paddingRight":"0px","paddingBottom":"0px","paddingLeft":"256px"}
  // },

  watch: {
    drawer: {
      handler(val) {
        if (val) {
          this.style_padding = {
            paddingTop: "64px",
            paddingRight: "0px",
            paddingBottom: "0px",
            paddingLeft: "256px",
          };
        } else {
          this.style_padding = {
            paddingTop: "64px",
            paddingRight: "0px",
            paddingBottom: "0px",
            paddingLeft: "56px",
          };
        }
      },
      immediate: true,
    },
  },
  mounted: function () {
    // window.axios = require("axios");
    // const generateRandomString = (length = 10) =>
    //   Math.random().toString(20).substr(2, length);

    this.items = [
      // {
      //   id:1,
      //   name: "Product",
      //   has_children: true,
      //   sub_children: false,
      //   icon: "mdi-alpha-p-circle",
      //   children: [
      //     {
      //       name: "Product Details",
      //       child: true,
      //       // to: "/product_view",
      //       icon: "mdi-alpha-d-circle",
      //     },
      //     {
      //       name: "Product Story",
      //       child: true,
      //       to: "/mrp",
      //       icon: "mdi-alpha-s-circle",
      //     },
      //   ],
      // },
      {
        id: 1,
        name: "Products",
        has_children: true,
        sub_children: false,
        icon: "mdi-alpha-m-circle",
        children: [
          {
            name: "Product Detail",
            child: true,
            to: "/product_details_view",
            icon: "mdi-alpha-d-circle",
          },
          {
            name: "Product Category",
            child: true,
            to: "/product_category",
            icon: "mdi-alpha-s-circle",
          },
          {
            name: "Product Sub Category",
            child: true,
            to: "/product_sub_category_view",
            icon: "mdi-alpha-s-circle",
          },
          {
            name: "Product Story",
            child: true,
            to: "/product_story",
            icon: "mdi-alpha-s-circle",
          },
        ],
      },
      {
        name: "Dashboard",
        has_children: false,
        sub_children: false,
        to: "/dashboard",
        icon: "mdi-alpha-s-circle",
      },
      {
        name: "User Details",
        has_children: false,
        sub_children: false,
        to: "/user_details",
        icon: "mdi-alpha-s-circle",
      },
      {
        name: "Chat",
        has_children: false,
        sub_children: false,
        to: "/chat_view",
        icon: "mdi-alpha-s-circle",
      },
      {
        name: "Story",
        has_children: false,
        sub_children: false,
        to: "/story",
        icon: "mdi-alpha-s-circle",
      },
    ];
  },
  data: () => ({
    dialog: false,
    drawer: false,
    mini: true,
    switch_dialog: false,
    entity_ID: null,
    entity_id: null,
    user_module_list: [],
    user_screen_list: [],
    items: [],
    style_padding: {},
  }),
  methods: {
    signOut() {
      this.dialog = false;
      // localStorage.getItem("user_details");
      localStorage.removeItem("user_details");
      router.push({ name: "login" });
    },
  },
};
</script>
