import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import Login from "../views/Login/login.vue";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/404",
      name: "PageNotFound",
      component: () => import("../views/PageData/PageNotFound.vue"),
    },

    {
      path: "/login",
      name: "login",
      component: Login,
      meta: {
        auth: true,
        page_code: "",
      },
    },
    {
      path: "/",
      name: "Home",
      component: HomeView,
      meta: {
        auth: true,
        page_code: "",
      },
      children: [
        // {
        //       path: "product_view",
        //       name: "product_view",
        //       meta: {
        //           auth: true,
        //           page_code: "",
        //       },
        //       component: () =>
        //           import ("../views/product/product_view.vue")
        //           ,
        //   },
        {
          path: "product_details_view",
          name: "product_details_view",
          meta: {
            auth: true,
            page_code: "",
          },
          component: () =>
            import("../views/product_details/product_details.vue"),
        },
        {
          path: "product_details_add",
          name: "product_details_add",
          meta: {
            auth: true,
            page_code: "",
          },
          component: () =>
            import("../views/product_details/product_details_add.vue"),
        },
        {
          path: "product_category_add",
          name: "product_category_add",
          meta: {
            auth: true,
            page_code: "",
          },
          component: () =>
            import("../views/product_category/product_category_add.vue"),
        },
        {
          path: "product_category",
          name: "product_category",
          meta: {
            auth: true,
            page_code: "",
          },
          component: () =>
            import("../views/product_category/product_category.vue"),
        },
        {
          path: "product_sub_category_add",
          name: "product_sub_category_add",
          meta: {
            auth: true,
            page_code: "",
          },
          component: () =>
            import(
              "../views/product_sub_category/product_sub_category_add.vue"
            ),
        },
        {
          path: "product_sub_category_view",
          name: "product_sub_category_view",
          meta: {
            auth: true,
            page_code: "",
          },
          component: () =>
            import(
              "../views/product_sub_category/product_sub_category_view.vue"
            ),
        },
        {
          path: "product_story",
          name: "product_story",
          meta: {
            auth: true,
            page_code: "",
          },
          component: () =>
            import("../views/product_story/product_story_view.vue"),
        },
        {
          path: "product_story_add",
          name: "product_story_add",
          meta: {
            auth: true,
            page_code: "",
          },
          component: () =>
            import("../views/product_story/product_story_add.vue"),
        },
        {
          path: "chat_view",
          name: "chat_view",
          meta: {
            auth: true,
            page_code: "",
          },
          component: () => import("../views/chat/chat_view.vue"),
        },
        {
          path: "/add_user",
          name: "AddUser",
          meta: {
            auth: true,
            page_code: "",
          },
          component: () => import("../views/AddUser/AddUser"),
        },
        {
          path: "/edit_user",
          name: "EditUser",
          meta: {
            auth: true,
            page_code: "",
          },
          component: () => import("../views/AddUser/EditUser"),
        },
        {
          path: "/user_details",
          name: "UserDetails",
          meta: {
            auth: true,
            page_code: "",
          },
          component: () => import("../views/AddUser/UserDetails"),
        },
        {
          path: "/dashboard",
          name: "Dashboard",
          meta: {
            auth: true,
            page_code: "",
          },
          component: () => import("../views/Dashboard/Dashboard.vue"),
        },
        {
          path: "/story",
          name: "Story",
          meta: {
            auth: true,
            page_code: "",
          },
          component: () => import("../views/story/Story.vue"),
        },
      ],
    },
  ],
});
router.beforeEach((to, from, next) => {
  if (
    to.path !== "/" &&
    !router.getRoutes().some((route) => route.path === to.path)
  ) {
    router.push({ name: "PageNotFound" });
  }
  if (
    to.meta.auth &&
    to.name !== "login" &&
    (localStorage.getItem("user_details") === undefined ||
      localStorage.getItem("user_details") === null)
  )
    next({ name: "login" });
  else next();
});
export default router;
