<template>
  <router-view></router-view>
</template>

<style>
#app {
  /* margin-top: 30px; */
}
.theme--light.v-navigation-drawer:not(.v-navigation-drawer--floating)
  .v-navigation-drawer__border {
  background-color: none !important;
}
html {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

html::-webkit-scrollbar {
  width: 0;
  height: 0;
}
</style>
<script>
export default {
  name: "App",
};
</script>
